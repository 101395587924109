export const CORPS = [
  {
    id: 'd1f8b1f2-2f0e-4b65-9c9b-1a9c8d728f14',
    name: '1-ci korpus',
    militarySpaces: [
      {
        id: 'a2d1b714-4c65-4f55-8d4e-2121347c2435',
        name: '1 saylı hərbi hissə',
      },
      {
        id: 'a2d9b714-4d65-4f55-8d4e-2121347c2430',
        name: '2 saylı hərbi hissə',
      },
    ],
  },
  {
    id: 'a23d1b78-9c4b-4b78-8d5e-987c78a1f213',
    name: '2-ci korpus',
    militarySpaces: [
      {
        id: '9ab1c712-d4c1-4f12-8d78-123456789abc',
        name: '1 saylı hərbi hissə',
      },
    ],
  },
  {
    id: 'f56d4b8f-7894-4d32-9d7a-24567890bcde',
    name: '3-cü korpus',
    militarySpaces: [
      {
        id: '84f7a9b6-3214-43d1-8c6d-9876543210ab',
        name: '1 saylı hərbi hissə',
      },
    ],
  },
  {
    id: 'd8b4c5d1-2345-4678-9ab1-1b9c1234d567',
    name: '4-cü korpus',
    militarySpaces: [
      {
        id: '1234c56d-7e89-4c23-91a1-567890abcdef',
        name: '1 saylı hərbi hissə',
      },
    ],
  },
  {
    id: 'e8f7c3b1-67d1-4321-8f9b-a1234b567c89',
    name: '5-ci korpus',
    militarySpaces: [
      {
        id: '5d8b4f7c-90ab-41c7-9871-c9876543ab21',
        name: '1 saylı hərbi hissə',
      },
    ],
  },
  {
    id: 'f7b3d4a1-345c-4f8e-91ab-432109876bcd',
    name: 'Hərbi Hava qüvvələri',
    militarySpaces: [
      {
        id: 'd7a8f9b3-123c-45d1-9872-1c34567b8912',
        name: '1 saylı hərbi hissə',
      },
    ],
  },
  {
    id: 'e1c4f7a3-12d4-47c8-8f91-789123c4d567',
    name: 'Hərbi Dəniz qüvvələri',
    militarySpaces: [
      {
        id: 'b6f3a1c7-6789-45f7-912b-4c12345f67d8',
        name: '1 saylı hərbi hissə',
      },
    ],
  },
  {
    id: 'f5a1d3b7-789c-4b56-8d12-9874c3a21f98',
    name: 'Quru Qoşunları',
    militarySpaces: [
      {
        id: '123f7b5c-9d01-456a-8f4b-1a9c8e7d6b2c',
        name: '1 saylı hərbi hissə',
      },
    ],
  },
  {
    id: 'd6f7b1c3-89a5-412b-9d3f-4c98712a3b98',
    name: 'Xüsusi Təyinatlı qüvvələr',
    militarySpaces: [
      {
        id: 'f7b9a8d3-9d4a-4e78-912b-3c9f876b123a',
        name: '1 saylı hərbi hissə',
      },
    ],
  },
];
