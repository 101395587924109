import { Result, Button, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';

const { Paragraph, Text } = Typography;

interface ErrorComponentProps {
  title?: string;
  description?: string;
  errors?: string[];
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
}

const Error: React.FC<ErrorComponentProps> = ({
  title = 'Bazaya qoşulma uğursuz oldu',
  description = 'Zəhmət olmasa, aşağıdakı məlumatları yoxlayın və yenidən yoxlayın.',
  errors = [
    'Sistemdə xəta baş verib.',
    'Sizin bu məlumatı görməyə hüququnuz ypxdur.',
  ],
  onPrimaryAction,
  onSecondaryAction,
}) => (
  <Result
    status="error"
    title={title}
    subTitle={description}
    extra={[
      <Button type="primary" key="retry" onClick={onPrimaryAction}>
        Yenidən Cəhd Et
      </Button>,
    ]}
  >
    <div className="desc">
      <Paragraph>
        <Text
          strong
          style={{
            fontSize: 16,
          }}
        >
          Göndərdiyiniz məzmun aşağıdakı səhvləri ehtiva edir:
        </Text>
      </Paragraph>
      {errors.map((error, index) => (
        <Paragraph key={index}>
          <CloseCircleOutlined style={{ color: '#a8071a', marginRight: 8 }} />
          {error}
        </Paragraph>
      ))}
    </div>
  </Result>
);

export default Error;
