import React, { useState } from 'react';
import { MainLayout } from '../components/layouts/MainLayout';
import { Avatar, DatePicker, List } from 'antd';
import Title from 'antd/es/typography/Title';
import { useQuery } from 'react-query';
import { getLogs } from '../api/services';
import { Loading } from '../components/main/Loading';

const { RangePicker } = DatePicker;

export const Logs = () => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);

  const {
    data: logs = [
      {
        username: 'admin',
        action: 'Babək adlı təzə istifadəçi yaratdı!',
        createdAt: new Date(),
      },
    ],
  } = useQuery(
    ['LOGS', { pageSize, currentPage, dateRange }],
    () => getLogs(pageSize, currentPage, dateRange),
    {
      select: (data) => data.logs,
    },
  );

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setDateRange(dateStrings);
    setCurrentPage(1); // Reset to the first page when date changes
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <MainLayout>
      <Title level={3}>Hərəkətlər</Title>
      <RangePicker
        style={{ marginBottom: 20 }}
        placeholder={['Başlanğıc tarixi', 'Son tarixi']}
        onChange={handleDateChange}
      />

      <div>
        <List
          pagination={{
            current: currentPage,
            pageSize,
            total: logs?.total || 0,
            onChange: handlePaginationChange,
            position: 'bottom',
            align: 'center',
          }}
          dataSource={logs || []}
          renderItem={(item: any) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={
                      item.avatarUrl || 'https://www.gravatar.com/avatar/?d=mp'
                    }
                  />
                }
                title={item.username}
                description={
                  <>
                    <p>{item.action}</p>
                    <span>{new Date(item.createdAt).toLocaleString()}</span>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </MainLayout>
  );
};
