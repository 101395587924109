import React from 'react';
import { useTable, useSortBy, Column, TableState } from 'react-table';
import { Table, Pagination } from 'antd';
import { ColumnProps } from 'antd/es/table';

interface SortableTableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  onRowClick?: (id: string) => void;
  isPaginated?: boolean;
  currentPage?: number;
  pageSize?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (size: number) => void;
  totalRecords?: number;
}

const SortableTable = <T extends object>({
  columns,
  data,
  onRowClick,
  isPaginated = false,
  currentPage = 1,
  pageSize = 10,
  onPageChange,
  onPageSizeChange,
  totalRecords = 0,
}: SortableTableProps<T>) => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable<T>(
    {
      autoResetHiddenColumns: false,
      columns,
      data,
      initialState: {
        sortBy: [],
      } as TableState<T>,
    },
    useSortBy,
  );

  const transformedColumns: ColumnProps<T>[] = headerGroups[0].headers.map(
    (column: any) => ({
      title: column.render('Header'),
      dataIndex: column.id,
      key: column.id,
    }),
  );

  const antdData = rows.map((row: any) => {
    prepareRow(row);
    return {
      key: row.original.id,
      ...row.values,
    };
  });

  const handleRowClick = (record: any) => {
    if (onRowClick) {
      onRowClick(record.key);
    }
  };

  return (
    <>
      <Table<T>
        {...getTableProps()}
        columns={transformedColumns as ColumnProps<T>[]}
        dataSource={antdData}
        pagination={false} // Disable built-in pagination as we're adding custom pagination
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
        locale={{
          emptyText: 'Məlumat yoxdur!',
        }}
      />
      {isPaginated && (
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalRecords}
          showSizeChanger
          onChange={(page) => onPageChange && onPageChange(page)}
          onShowSizeChange={(_, size) =>
            onPageSizeChange && onPageSizeChange(size)
          }
          style={{ marginTop: 16, textAlign: 'right' }}
        />
      )}
    </>
  );
};

export default SortableTable;
