import { Select, Input, Typography, Button, message, Space } from 'antd';
import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { fieldArray, fields } from '../../../constants/fields';
import { getStatisticById } from '../../../api/services';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { monthNames } from '../../../constants/months';

const { Title } = Typography;

type FieldsKey = keyof typeof fields;
interface IStatTableProps {
  currentFields: Array<FieldsKey>;
}

const StatTable = ({ currentFields }: IStatTableProps) => {
  const [year, setYear] = useState<number>(2024);
  const [selectedField, setSelectedField] = useState<FieldsKey>('RankSalary');

  const { id } = useParams();

  const {
    data: chartData = [],
    refetch,
    isFetching,
  } = useQuery(
    ['getStatistic', year, selectedField],
    async () => getStatisticById(id!, year, selectedField),
    {
      enabled: false,
      select: (data) => {
        return Object.entries(data).map(([month, value]) => ({
          month: monthNames[parseInt(month, 10) - 1],
          value: Number(value),
        }));
      },
      onError: () => message.error('Failed to load data.'),
      onSuccess: () => message.success('Data loaded successfully!'),
    },
  );

  const handleFetchData = () => {
    refetch();
  };

  return (
    <>
      <Title level={3}>Statistikalar</Title>

      <Space style={{ marginBottom: 16 }}>
        <Input
          type="number"
          value={year}
          onChange={(e) => setYear(Number(e.target.value))}
          style={{ width: '80px' }}
        />
        <Select
          value={'Hərbi rütbə maaşı'}
          // onChange={(value) => setSelectedField(value as FieldsKey)}
          style={{ width: '150px' }}
        >
          {Object.entries(fields).map(([key, value]) => (
            <Select.Option key={key} value={key}>
              {value}
            </Select.Option>
          ))}
        </Select>
        <Button type="primary" onClick={handleFetchData} loading={isFetching}>
          Məlumatları Yüklə
        </Button>
      </Space>

      {/* Line Chart for Monthly Data */}
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={[
            { month: 'Noyabr', value: 1200 },
            { month: 'Dekabr', value: 1900 },
          ]}
          margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default StatTable;
