import { Form, Select, Input, Typography, Button, message } from 'antd';
import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { MainLayout } from '../components/layouts/MainLayout';
import { fields } from '../constants/fields';
import { getStatistic } from '../api/services'; // Assuming this is the API call function
import { useMutation } from 'react-query';
import { monthNames } from '../constants/months';

const { Title } = Typography;

const checkboxData = Object.entries(fields);

const Stats = () => {
  const [form] = Form.useForm();
  const [year, setYear] = useState('2024');
  const [chartData, setChartData] = useState<
    { month: string; value: number }[]
  >([]);

  // Define a mutation for fetching statistics
  const { mutate, isLoading } = useMutation(
    async ({ key, year }: { key: string; year: string }) => {
      const response = await getStatistic(parseInt(year), key); // Adjust API function if needed
      return response;
    },
    {
      onSuccess: (data) => {
        const transformedData = Object.entries(data).map(([month, value]) => ({
          month: monthNames[parseInt(month, 10) - 1],
          value: Number(value),
        }));
        setChartData(transformedData);
        message.success('Data loaded successfully!');
      },
      onError: () => {
        message.error('Failed to load data.');
      },
    },
  );

  const handleFormSubmit = (values: { key: string; year: string }) => {
    mutate({ key: values.key, year: values.year });
  };

  return (
    <MainLayout>
      <Title level={3}>Statistikalar</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        style={{ marginBottom: 20 }}
        initialValues={{ year: year }}
      >
        <Form.Item
          label="Hesablama növünü seçin"
          name="key"
          rules={[
            { required: true, message: 'Zəhmət olmasa Hesablama növünü seçin' },
          ]}
        >
          <Select placeholder="Hesablama növünü seçin">
            {checkboxData.map(([key, label]) => (
              <Select.Option key={key} value={key}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="İl"
          name="year"
          rules={[{ required: true, message: 'Zəhmət olmasa ili daxil edin' }]}
        >
          <Input
            placeholder="İli daxil edin"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Məlumatları Yüklə
          </Button>
        </Form.Item>
      </Form>

      {/* Line Chart for Monthly Data */}
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={[
            { month: 'Noyabr', value: 1200 },
            { month: 'Dekabr', value: 1900 },
          ]}
          margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </MainLayout>
  );
};

export default Stats;
