import React from 'react';
import { useNavigate } from 'react-router';
// import Cookie from '../utils/Cookie';
import { useAuth } from '../hooks/useAuth';
// import { Roles } from '../constants/roles';

const withAuth = (Component: React.FunctionComponent, isProtected = false) => {
  return () => {
    let navigate = useNavigate();
    const { userInfo } = useAuth();
    const user = userInfo();

    // useEffect(() => {
    //   const token = Cookie.getCookie('token');
    //   if (!token) {
    //     navigate('/login');
    //   }
    // }, [navigate]);

    // useEffect(() => {
    //   if (isProtected && user?.role !== Roles.Admin) {
    //     navigate('/login');
    //   }
    // }, [isProtected, user?.role, navigate]);

    return <Component />;
  };
};

export default withAuth;
