import { Button, Flex, Select } from 'antd';
import React, { useState } from 'react';
import SortableTable from '../../../components/main/SortableTable';
import { Column } from 'react-table';
import Input from 'antd/es/input/Input';
import { SyncOutlined } from '@ant-design/icons';
import { MONTHS } from '../../../constants/months';
import { useNavigate } from 'react-router-dom';
import withAuth from '../../../hocs/withAuth';
import { ISalary } from '../../../types/Salary';

const columns: Column<ISalary>[] = [
  {
    Header: 'Ad Soyad Ata adı',
    accessor: 'fullName',
  },
  {
    Header: 'FIN',
    accessor: 'pin',
  },
  {
    Header: 'Rütbə',
    accessor: (row) => row.salaryCalculation.rankSalary,
  },
  {
    Header: 'Vəzifə',
    accessor: (row) => row.salaryCalculation.positionSalary,
  },
  {
    Header: 'Maaş',
    accessor: (row) => row.salaryCalculation.totalSalary,
  },
];

// Fake constant data
const fakeData: ISalary[] = [
  {
    id: '1',
    fullName: 'Ali Vəliyev',
    pin: 'FIN12345',
    salaryCalculation: {
      rankSalary: 500,
      positionSalary: 600,
      totalSalary: 1100,
    },
  },
  {
    id: '2',
    fullName: 'Əhməd Quliyev',
    pin: 'FIN67890',
    salaryCalculation: {
      rankSalary: 700,
      positionSalary: 800,
      totalSalary: 1500,
    },
  },
  {
    id: '3',
    fullName: 'Zeynəb Əliyeva',
    pin: 'FIN11223',
    salaryCalculation: {
      rankSalary: 600,
      positionSalary: 750,
      totalSalary: 1350,
    },
  },
];

const SalaryTable = () => {
  const navigate = useNavigate();
  const onRowClick = (id: string) => {
    navigate(`/calculation/${id}`);
  };

  const [date, setDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const [searchQuery, setSearchQuery] = useState(''); // State for search input

  // Apply search filter to data based on fullName
  const filteredData = fakeData.filter((item) =>
    item.fullName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <>
      <Flex justify={'space-between'} style={{ margin: '8px 0' }}>
        <Flex gap={10}>
          <Input
            type="number"
            defaultValue={date.year}
            onChange={(e) =>
              setDate((pre) => ({ ...pre, year: Number(e.target.value) }))
            }
            style={{ width: '80px' }}
          />
          <Select
            defaultValue={date.month}
            onChange={(e) => setDate((pre) => ({ ...pre, month: e }))}
          >
            {MONTHS.map((month) => (
              <Select.Option value={month.id} key={month.id}>
                {month.name}
              </Select.Option>
            ))}
          </Select>
          <Input
            type="text"
            style={{ width: '50%' }}
            placeholder="Ad/Soyad/FIN"
            value={searchQuery} // Bind the input value to searchQuery
            onChange={(e) => setSearchQuery(e.target.value)} // Update the searchQuery state
          />
        </Flex>
        <div>
          <Button style={{ marginRight: '16px' }}>Gələn aya köçür</Button>
          <Button>
            <SyncOutlined />
          </Button>
        </div>
      </Flex>
      <SortableTable
        columns={columns}
        data={filteredData}
        onRowClick={onRowClick}
      />
    </>
  );
};

export default withAuth(SalaryTable);
