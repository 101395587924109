import React from 'react';
import './styles/global.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Login } from './pages/Login';
import Home from './pages/Home';
import Sections from './pages/Section';
import Profile from './pages/Profile';
import CalculationProfile from './pages/CalculationProfile';
import CalculationEditProfile from './pages/CalculationEditProfile';
import Settings from './pages/Settings';
import { Logs } from './pages/Logs';
import { QueryClient, QueryClientProvider } from 'react-query';
import AddUser from './pages/AddUser';
import Stats from './pages/Stats';
import NotFound from './pages/NotFound';

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/login" Component={Login} />
          <Route path="/sections/:id" Component={Sections} />
          <Route path="/profile" Component={Profile} />
          <Route path="/calculation/:id" Component={CalculationProfile} />
          <Route
            path="/calculation/:id/edit"
            Component={CalculationEditProfile}
          />
          <Route path="/settings" Component={Settings} />
          <Route path="/logs" Component={Logs} />
          <Route path="/add-user" Component={AddUser} />
          <Route path="/stats" Component={Stats} />
          <Route path="/*" Component={NotFound} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
