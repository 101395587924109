import React from 'react';
import { Layout, Menu, Dropdown, Avatar, Typography } from 'antd';
import {
  UserOutlined,
  DownOutlined,
  LogoutOutlined,
  SettingOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import '../../styles/header.scss';
import logo from '../../assets/logo.png';
import Title from 'antd/es/typography/Title';
import { Link } from 'react-router-dom';
import Cookie from '../../utils/Cookie';
import { useAuth } from '../../hooks/useAuth';
import { When } from '../main/When';
import { Roles } from '../../constants/roles';

const { Header } = Layout;
const { Text } = Typography;

const AppHeader: React.FC = () => {
  const { userInfo } = useAuth();
  const user = userInfo();

  const logout = () => {
    Cookie.eraseCookie('token');
    window.location.reload();
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        <Link to={'/profile'}>Profil</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<PlusCircleOutlined />}>
        <Link to={'/add-user'}>İstifadəçi əlavə et</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<SettingOutlined />}>
        <Link to={'/settings'}>Tənzimləmələr</Link>
      </Menu.Item>
      <Menu.Item onClick={logout} key="4" icon={<LogoutOutlined />}>
        Çıxış et
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header">
      <Link to={'/'}>
        <div className="logo">
          <img src={logo} alt="logo" />
          <Title
            level={4}
            style={{ margin: '0', color: '#fff', marginLeft: '32px' }}
          >
            Maliyyə Hesabatının idarə olunması
          </Title>
        </div>
      </Link>
      <div className="profile-section">
        <Dropdown overlay={menu}>
          <div className="profile-dropdown">
            <Avatar icon={<UserOutlined />} />
            <Text style={{ color: '#fff', marginLeft: 8 }}>
              {user?.unique_name}
            </Text>
            <DownOutlined style={{ color: '#fff', marginLeft: 4 }} />
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;
