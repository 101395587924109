import React from 'react';
import { Button, Modal, Form, Input, Spin } from 'antd';

interface CreateModalProps {
  open: boolean;
  showModal: () => void;
  handleCancel: () => void;
}

export const CreateModal: React.FC<CreateModalProps> = ({
  open,
  showModal,
  handleCancel,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = (values: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <>
      <Modal
        title="Hərbi hissə əlavə et"
        open={open}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{ name: '', email: '' }}
        >
          <Form.Item
            name="name"
            label="Hərbi hissə adı"
            rules={[{ required: true, message: 'Bu xana məcburidir!' }]}
          >
            <Input placeholder="Daxil edin" />
          </Form.Item>

          <Form.Item name="email" label="Ünvan">
            <Input placeholder="Daxil edin" />
          </Form.Item>

          <Form.Item>
            <Button
              type="default"
              onClick={handleCancel}
              style={{ marginRight: '8px' }}
            >
              Ləğv et
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Əlavə et
            </Button>
          </Form.Item>
        </Form>

        {loading && (
          <Spin
            style={{ display: 'block', textAlign: 'center', marginTop: '20px' }}
          />
        )}
      </Modal>
    </>
  );
};
