import React from 'react';
import { InputNumber } from 'antd';

interface CalculationNumberInputProps {
  inputType?: 'salary' | 'percentage';
  style?: React.CSSProperties;
  [key: string]: any; // For other props like defaultValue, disabled, etc.
}

export const CalculationNumberInput: React.FC<CalculationNumberInputProps> = ({
  inputType = 'salary', // Default to salary
  ...props
}) => {
  return (
    <InputNumber
      min={0}
      style={{ width: '100%', textAlign: 'right', ...props?.style }}
      formatter={(value) => {
        if (inputType === 'percentage') {
          return `${value}%`; // Show percentage sign without toFixed
        }
        return `${Number(value).toFixed(2)}`; // Default for salary or other types with fixed decimal
      }}
      {...props}
    />
  );
};
