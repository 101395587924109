export const CORPS = [
  {
    id: 1,
    name: '1-ci korpus',
    militarySpaces: [{ id: 1, name: '1 saylı hərbi hissə' }],
  },
  {
    id: 2,
    name: '2-ci korpus',
    militarySpaces: [{ id: 3, name: '1 saylı hərbi hissə' }],
  },
  {
    id: 3,
    name: '3-cü korpus',
    militarySpaces: [{ id: 5, name: '1 saylı hərbi hissə' }],
  },
  {
    id: 4,
    name: '4-cü korpus',
    militarySpaces: [{ id: 13, name: '1 saylı hərbi hissə' }],
  },
  {
    id: 5,
    name: '5-ci korpus',
    militarySpaces: [{ id: 1123, name: '1 saylı hərbi hissə' }],
  },
  {
    id: 6,
    name: 'Hərbi Hava qüvvələri',
    militarySpaces: [{ id: 123, name: '1 saylı hərbi hissə' }],
  },
  {
    id: 7,
    name: 'Hərbi Dəniz qüvvələri',
    militarySpaces: [{ id: 14, name: '1 saylı hərbi hissə' }],
  },
  {
    id: 8,
    name: 'Quru Qoşunları',
    militarySpaces: [{ id: 166, name: '1 saylı hərbi hissə' }],
  },
  {
    id: 9,
    name: 'Xüsusi Təyinatlı qüvvələr',
    militarySpaces: [{ id: 21, name: '1 saylı hərbi hissə' }],
  },
];

export const userData = [
  {
    name: 'Elvin Məmmədov',
    fin: 'FIN001',
    rank: 'Leytenant',
    position: 'Mühəndis',
    salary: '1500 AZN',
  },
  {
    name: 'Nərgiz Əliyeva',
    fin: 'FIN002',
    rank: 'Kapitan',
    position: 'Hüquqşünas',
    salary: '1800 AZN',
  },
  {
    name: 'Murad Hüseynov',
    fin: 'FIN003',
    rank: 'Mayor',
    position: 'İnzibatçı',
    salary: '2200 AZN',
  },
  {
    name: 'Leyla Quliyeva',
    fin: 'FIN004',
    rank: 'Polkovnik',
    position: 'Təlimatçı',
    salary: '2500 AZN',
  },
  {
    name: 'Rəşad Abbasov',
    fin: 'FIN005',
    rank: 'Baş leytenant',
    position: 'Proqramçı',
    salary: '2000 AZN',
  },
  {
    name: 'Zaur İsmayılov',
    fin: 'FIN006',
    rank: 'Baş kapitan',
    position: 'Maliyyəçi',
    salary: '2300 AZN',
  },
  {
    name: 'Sevinc Mustafayeva',
    fin: 'FIN007',
    rank: 'Serjant',
    position: 'Mühasib',
    salary: '1600 AZN',
  },
  {
    name: 'Orxan Əliyev',
    fin: 'FIN008',
    rank: 'Mayor',
    position: 'Təchizatçı',
    salary: '2100 AZN',
  },
  {
    name: 'Tamerlan Həsənov',
    fin: 'FIN009',
    rank: 'Leytenant',
    position: 'Təhlükəsizlik mütəxəssisi',
    salary: '1900 AZN',
  },
  {
    name: 'Gülər Abbasova',
    fin: 'FIN010',
    rank: 'Kapitan',
    position: 'HR meneceri',
    salary: '1700 AZN',
  },
];
