import React, { useState } from 'react';
import Header from './Header';
import { Layout, theme } from 'antd';
import WizardMenu from './WizardMenu';
import { Content } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

export const MainLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <>
      <Header />
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            collapsed ? (
              <DoubleRightOutlined
                style={{ fontSize: '16px', color: '#fff' }}
              />
            ) : (
              <DoubleLeftOutlined style={{ fontSize: '16px', color: '#fff' }} />
            )
          }
        >
          <div className="demo-logo-vertical" />
          <WizardMenu collapsed={collapsed} /> {/* Pass collapsed state */}
        </Sider>
        <Layout>
          <Content style={{ margin: '32px' }}>
            <div
              style={{
                padding: 24,
                minHeight: 360,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
