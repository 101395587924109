import React, { useState } from 'react';
import { MainLayout } from '../../components/layouts/MainLayout';
import { Flex, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import SortableTable from '../../components/main/SortableTable';
import { Column } from 'react-table';
import withAuth from '../../hocs/withAuth';
import Input from 'antd/es/input/Input';
import { MONTHS } from '../../constants/months';
import { useQuery } from 'react-query';
import { getCorps, getSalaryRecordByDate } from '../../api/services';
import { CORPS } from '../../constants/fakeAPIData';
import { ISalary } from '../../types/Salary';
import { Loading } from '../../components/main/Loading';

const columns: Column<ISalary>[] = [
  {
    Header: 'Ad Soyad',
    accessor: 'fullName',
  },
  {
    Header: 'FIN',
    accessor: 'pin',
  },
  {
    Header: 'Rütbə',
    accessor: (row) => row.salaryCalculation.rankSalary,
  },
  {
    Header: 'Vəzifə',
    accessor: (row) => row.salaryCalculation.positionSalary,
  },
  {
    Header: 'Maaş',
    accessor: (row) => row.salaryCalculation.totalSalary,
  },
];

const Index = () => {
  const [date, setDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  const [selectCorps, setSelectCorps] = useState<string | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>(''); // Search term state
  const [pageSize, setPageSize] = useState<number>(10); // Page size state
  const [pageNumber, setPageNumber] = useState<number>(1); // Page number state

  const { data: corpsList } = useQuery('corps', async () => {
    try {
      const result = await getCorps();
      const defaultCorps = result.data[0]?.id;
      setSelectCorps(defaultCorps); // Set default corps
      return result.data;
    } catch {
      return CORPS;
    }
  });

  const {
    data = [],
    isLoading,
    isError,
  } = useQuery(
    ['GET_CORPS_SALARIES', selectCorps, date, pageSize, pageNumber],
    () =>
      getSalaryRecordByDate(
        selectCorps!,
        date.month,
        date.year,
        pageSize,
        pageNumber,
      ),
    { enabled: Boolean(selectCorps) },
  );

  const filteredData = data.filter((item: ISalary) =>
    item.fullName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <MainLayout>
      <>
        <Title level={3}>Xoş gəlmisiniz!</Title>
        <Flex justify="space-between" style={{ marginBottom: 20 }}>
          <Flex gap={20}>
            <Input
              type="text"
              style={{ width: '50%' }}
              placeholder="Ad/Soyad/FIN"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Select
              style={{ width: '75%' }}
              defaultValue={'Korpus seçin'}
              onChange={setSelectCorps}
              value={selectCorps}
            >
              {corpsList?.map((x: any) => (
                <Select.Option key={x.id} value={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
            <Input
              value={date.year}
              onChange={(e) => {
                setDate((prev) => ({ ...prev, year: Number(e.target.value) }));
              }}
              type="number"
              style={{ width: '40%' }}
            />
            <Select
              style={{ width: '100%' }}
              defaultValue={date.month}
              onChange={(month) => {
                setDate((prev) => ({ ...prev, month }));
              }}
            >
              {MONTHS.map((month) => (
                <Select.Option key={month.id} value={month.id}>
                  {month.name}
                </Select.Option>
              ))}
            </Select>
          </Flex>
        </Flex>
        <Loading isLoading={isLoading} isHasError={isError}>
          <div style={{ width: '100%' }}>
            <SortableTable
              columns={columns}
              data={filteredData}
              isPaginated={true}
              currentPage={pageNumber}
              pageSize={pageSize}
              onPageChange={(newPage) => setPageNumber(newPage)}
              onPageSizeChange={(newSize) => {
                setPageSize(newSize);
                setPageNumber(1);
              }}
            />
          </div>
        </Loading>
      </>
    </MainLayout>
  );
};

export default withAuth(Index);
