import Crypt from './Crypt';

class Cookie {
  public static setCookie(name: string, value: string, days: number) {
    let expires = '';

    const encryptedKey = Crypt.encrypt(name);

    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // 24 hours
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  static getCookie = (key: string): string => {
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split('; ');
    let res = '';

    cArr.forEach((val) => {
      if (val.indexOf(key) === 0) {
        res = val.substring(key.length);
      }
    });

    return res.slice(1);
  };

  public static eraseCookie(name: string) {
    this.setCookie(name, '', 0);
  }
}

export default Cookie;
