import { Spin, Alert } from 'antd';
import React from 'react';
import Error from './Error';

interface LoadingProps {
  children: React.ReactNode;
  isLoading?: boolean;
  isHasError?: boolean; // Add error prop
}

export const Loading: React.FC<LoadingProps> = ({
  children,
  isLoading = false,
  isHasError,
}) => {
  if (isHasError) {
    return <Error />;
  }

  return isLoading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Spin tip="Yüklənir..." />
    </div>
  ) : (
    <>{children || null}</>
  );
};
