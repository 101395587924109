import { BASE_URL } from '../constants/baseUrl';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import Cookie from '../utils/Cookie';

// Configure request params
const config: AxiosRequestConfig<any> = {
  baseURL: BASE_URL,
  timeout: 5000,
};
const service: AxiosInstance = axios.create(config);

// Intercept request
service.interceptors.request.use(
  (config) => {
    const accessToken = Cookie.getCookie('token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// Intercept response
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const contentType = response.headers['content-type'];

    if (contentType && contentType.includes('application/pdf')) {
      // The response is a PDF (or another binary format like images)
      return response.data; // Return the blob directly
    }

    if (contentType && contentType.includes('application/octet-stream')) {
      // The response is likely some binary data (byte array or file)
      return response.data; // Return the blob or byte array directly
    }

    return response.data?.data;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

export default service;
