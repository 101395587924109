import React, { useEffect } from 'react';
import { Form, Input, Button, Typography, theme, Flex, message } from 'antd';
import logo from '../assets/logo.png';
import { loginService } from '../api/services';
import { useMutation } from 'react-query';
import Cookie from '../utils/Cookie';
import { useNavigate } from 'react-router';

const { Title } = Typography;

export const Login: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  let nav = useNavigate();

  const mutate = useMutation(loginService, {
    onSuccess: (data) => {
      Cookie.setCookie('token', data.token, 7);
      nav('/');
    },
  });

  useEffect(() => {
    const token = Cookie.getCookie('token');
    if (!token) {
      nav('/');
    }
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      await mutate.mutateAsync(values);
    } catch (err) {
      messageApi.open({
        type: 'error',
        content: 'Xəta baş verdi!',
      });
    }
  };

  return (
    <Flex
      justify="center"
      align="center"
      style={{
        padding: '20px',
        height: '100vh',
        backgroundColor: 'whitesmoke',
      }}
    >
      {contextHolder}
      <Flex
        gap={12}
        justify="space-around"
        style={{
          padding: '24px',
          backgroundColor: 'whitesmoke',
          borderRadius: borderRadiusLG,
          width: 600,
          height: 300,
        }}
      >
        <img src={logo} alt="logo" />
        <div>
          <Title level={2}>Maliyyə və Büdcə İdarəsi</Title>
          <Form
            name="login"
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{ email: '', password: '' }}
          >
            <Form.Item
              label="İstifadəçi adı"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'İstifadəçi adı daxil edin!',
                },
              ]}
            >
              <Input placeholder="Daxil edin" />
            </Form.Item>

            <Form.Item
              label="Şifrə"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Şifrə daxil edin!',
                },
                {
                  min: 6,
                  message: 'Şifrə minimum 6 simvol olmalıdır!',
                },
              ]}
            >
              <Input.Password placeholder="Daxil edin" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Daxil ol
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Flex>
    </Flex>
  );
};
