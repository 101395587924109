export const fields = {
  RankSalary: 'Hərbi rütbə maaşı',
  PositionSalary: 'Vəzifə maaşı',
  ServiceYearBonus: 'Xidmət ilinə görə əlavə',
  skillDegreeBonus: 'Məharət dərəcəsinə görə əlavə',
  representationBonus: 'Təmsilçilik əlavəsi',
  hazardPay: 'Zərərlik əlavəsi',
  confidentialityBonus: 'Məxfilik əlavəsi',
  foreignLanguageBonus: 'Xarici dil əlavəsi',
  intelligenceAward: 'Kəş.mük.',
  scientificDegreeBonus: 'Elmi dərəc. görə əlav.',
  cyberSecurityBonus: 'Kibertəhlük. əlav.',
  honoraryTitleBonus: 'Fəxri ada görə əlav.',
  additionalGovtPayment: 'Əlavə ödən.(gvt)',
  foodAllowance: 'Ərzaq paylanması',
  housingRentCompensation: 'Mənzil kirayəsi kompensasiya',
  financialAid: 'Maddi yardim',
  vacation: 'Məzuniyyət',
  severancePay: 'Çıxış müavinatı',
  businessTripAllowance: 'Ezamiyyet',
  FieldAllowance: 'Səhra pulu',
  travelCost: 'Yol xərci',
  borderServiceBonus:
    'Ermənistanla şərti dövlət sərhəddində xidmətinə görə əlavə',
  liberatedAreasBonus: 'İşğaldan azad edilmiş ərazilərdə xidmətinə görə əlavə',
  borderServicePresBonus:
    'Ermənistanla şərti dövlət sərhəddində xidmətinə görə Prezident əlavəsi',
  liberatedAreasPresBonus:
    'İşğaldan azad edilmiş ərazilərdə xidmətinə görə Prezident əlavəsi',
  specialForcesBonus:
    'Əlahiddə Ümumqoşun Ordu tərkibində xüsusi təyinatlı hərbi qulluqçular üçün əlavə',
  presidentialBonus: 'Prezidentin əlavəsi',
  specialConditionsBonus: 'idmətin xüsusi şəraitinə görə əlavələr',
  highMountainBonus: 'Yüksək dağlıq ərazilərə görə əlavə',
  encryptionBonus: 'Şifrələmə əlavəsi',
  trainingDailyAllowance: 'Təlim vəyoxlama toplanışlarda gündəlik ödəniş',
  navalServiceBonus: 'Dəniz pul təminatı',
  combatDutyBonus: 'Döyüş növbəsinə görə mükafat',
  mobilizationCompensation: 'Səfərbərlik əsasında h. ödənilən müavinat',
  internationalContractPayments: 'Dövlətlərarası müqavilə əsaslı ödənişlər',
  specialForcesIncreaseCoefficient: 'XTQ üzrə artım əmsalı',
  parachuteJumpAward: 'Paraşutla tullanma mükafatı',
  divingWorkAward: 'Dalğıc işləri üzrə  mükafat',
  nonComOfficerCompensation: 'Gizirlərə h. almaq üçün verilən müavinat',
  officerSeveranceCompensation:
    'Zabitlərə. təxris olunarkən birdəfəlik müavinat',
  conscriptSeveranceCompensation:
    'Müddətli h. təxris olunarkən birdəfəlik müavinat',
};

export const fieldArray = [
  // Quru Qoşunları (Ground Forces)
  {
    label: 'Hərbi rütbə maaşı',
    key: 'RankSalary',
    accessor: 'rankSalary',
    group: 'Ümumi',
  },
  {
    label: 'Vəzifə maaşı',
    key: 'PositionSalary',
    accessor: 'positionSalary',
    group: 'Ümumi',
  },
  {
    label: 'Xidmət ilinə görə əlavə',
    key: 'ServiceYearBonus',
    accessor: 'serviceYearBonus',
    group: 'Quru Qoşunları',
  },
  {
    label: 'Səhra pulu',
    key: 'FieldAllowance',
    accessor: 'fieldAllowance',
    group: 'Quru Qoşunları',
  },
  {
    label: 'Yüksək dağlıq ərazilərə görə əlavə',
    key: 'highMountainBonus',
    accessor: 'highMountainBonus',
    group: 'Quru Qoşunları',
  },
  {
    label: 'Xidmətin xüsusi şəraitinə görə əlavələr',
    key: 'specialConditionsBonus',
    accessor: 'specialConditionsBonus',
    group: 'Quru Qoşunları',
  },
  {
    label: 'Döyüş növbəsinə görə mükafat',
    key: 'combatDutyBonus',
    accessor: 'combatDutyBonus',
    group: 'Quru Qoşunları',
  },

  // Hərbi Hava Qüvvələri (Air Forces)
  {
    label: 'Məharət dərəcəsinə görə əlavə',
    key: 'skillDegreeBonus',
    accessor: 'skillDegreeBonus',
    group: 'Hərbi Hava Qüvvələri',
  },
  {
    label: 'Xarici dil əlavəsi',
    key: 'foreignLanguageBonus',
    accessor: 'foreignLanguageBonus',
    group: 'Hərbi Hava Qüvvələri',
  },
  {
    label: 'Kəş.mük.',
    key: 'intelligenceAward',
    accessor: 'intelligenceAward',
    group: 'Hərbi Hava Qüvvələri',
  },
  {
    label: 'Zərərlik əlavəsi',
    key: 'hazardPay',
    accessor: 'hazardPay',
    group: 'Hərbi Hava Qüvvələri',
  },

  // Hərbi Dəniz Qüvvələri (Naval Forces)
  {
    label: 'Dəniz pul təminatı',
    key: 'navalServiceBonus',
    accessor: 'navalServiceBonus',
    group: 'Hərbi Dəniz Qüvvələri',
  },
  {
    label: 'Dalğıc işləri üzrə mükafat',
    key: 'divingWorkAward',
    accessor: 'divingWorkAward',
    group: 'Hərbi Dəniz Qüvvələri',
  },

  // Xüsusi Təyinatlı (Special Forces)
  {
    label: 'Xüsusi təyinatlı hərbi qulluqçular üçün əlavə',
    key: 'specialForcesBonus',
    accessor: 'specialForcesBonus',
    group: 'Xüsusi Təyinatlı',
  },
  {
    label: 'XTQ üzrə artım əmsalı',
    key: 'specialForcesIncreaseCoefficient',
    accessor: 'specialForcesIncreaseCoefficient',
    group: 'Xüsusi Təyinatlı',
  },
  {
    label: 'Paraşutla tullanma mükafatı',
    key: 'parachuteJumpAward',
    accessor: 'parachuteJumpAward',
    group: 'Hərbi Hava Qüvvələri',
  },

  // Əlahiddə Ümumqoşun Ordu (Combined-Arms Army)
  {
    label: 'Təmsilçilik əlavəsi',
    key: 'representationBonus',
    accessor: 'representationBonus',
    group: 'Ümumi',
  },
  {
    label: 'Məxfilik əlavəsi',
    key: 'confidentialityBonus',
    accessor: 'confidentialityBonus',
    group: 'Xüsusi Təyinatlı',
  },
  {
    label: 'Şifrələmə əlavəsi',
    key: 'encryptionBonus',
    accessor: 'encryptionBonus',
    group: 'Ümumi',
  },
  {
    label: 'Prezidentin əlavəsi',
    key: 'presidentialBonus',
    accessor: 'presidentialBonus',
    group: 'Ümumi',
  },

  // Ümumi (General)
  {
    label: 'Elmi dərəc. görə əlav.',
    key: 'scientificDegreeBonus',
    accessor: 'scientificDegreeBonus',
    group: 'Ümumi',
  },
  {
    label: 'Fəxri ada görə əlav.',
    key: 'honoraryTitleBonus',
    accessor: 'honoraryTitleBonus',
    group: 'Ümumi',
  },
  {
    label: 'Əlavə ödən.(gvt)',
    key: 'additionalGovtPayment',
    accessor: 'additionalGovtPayment',
    group: 'Ümumi',
  },
  {
    label: 'Ərzaq paylanması',
    key: 'foodAllowance',
    accessor: 'foodAllowance',
    group: 'Ümumi',
  },
  {
    label: 'Mənzil kirayəsi kompensasiya',
    key: 'housingRentCompensation',
    accessor: 'housingRentCompensation',
    group: 'Ümumi',
  },
  {
    label: 'Maddi yardim',
    key: 'financialAid',
    accessor: 'financialAid',
    group: 'Ümumi',
  },
  {
    label: 'Məzuniyyət',
    key: 'vacation',
    accessor: 'vacation',
    group: 'Ümumi',
  },
  {
    label: 'Çıxış müavinatı',
    key: 'severancePay',
    accessor: 'severancePay',
    group: 'Ümumi',
  },
  {
    label: 'Ezamiyyet',
    key: 'businessTripAllowance',
    accessor: 'businessTripAllowance',
    group: 'Ümumi',
  },
  {
    label: 'Yol xərci',
    key: 'travelCost',
    accessor: 'travelCost',
    group: 'Ümumi',
  },
  {
    label: 'Ermənistanla sərhəddə xidmətinə görə əlavə',
    key: 'borderServiceBonus',
    accessor: 'borderServiceBonus',
    group: 'Quru Qoşunları',
  },
  {
    label: 'Azad ərazilərdə xidmətinə görə əlavə',
    key: 'liberatedAreasBonus',
    accessor: 'liberatedAreasBonus',
    group: 'Quru Qoşunları',
  },
  {
    label: 'Sərhəddə xidmətə Prezident əlavəsi',
    key: 'borderServicePresBonus',
    accessor: 'borderServicePresBonus',
    group: 'Quru Qoşunları',
  },
  {
    label: 'Azad ərazilərə görə Prezident əlavəsi',
    key: 'liberatedAreasPresBonus',
    accessor: 'liberatedAreasPresBonus',
    group: 'Quru Qoşunları',
  },
  {
    label: 'Təlim gündəlik ödəniş',
    key: 'trainingDailyAllowance',
    accessor: 'trainingDailyAllowance',
    group: 'Ümumi',
  },
  {
    label: 'Səfərbərlik üzrə müavinat',
    key: 'mobilizationCompensation',
    accessor: 'mobilizationCompensation',
    group: 'Ümumi',
  },
  {
    label: 'Müqavilə əsaslı ödənişlər',
    key: 'internationalContractPayments',
    accessor: 'internationalContractPayments',
    group: 'Ümumi',
  },
  {
    label: 'Gizirlərə müavinat',
    key: 'nonComOfficerCompensation',
    accessor: 'nonComOfficerCompensation',
    group: 'Ümumi',
  },
  {
    label: 'Zabitlər üçün müavinat',
    key: 'officerSeveranceCompensation',
    accessor: 'officerSeveranceCompensation',
    group: 'Ümumi',
  },
  {
    label: 'Müddətli hərbçilərə müavinat',
    key: 'conscriptSeveranceCompensation',
    accessor: 'conscriptSeveranceCompensation',
    group: 'Ümumi',
  },
];
