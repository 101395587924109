import React from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { fields } from '../../../constants/fields';
import { downloadReportPdf } from '../../../api/services';

type FieldsKey = keyof typeof fields;

interface ThreeDotMenuProps {
  subMenuItems: Array<FieldsKey>;
  military: any;
}

const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({
  subMenuItems,
  military,
}) => {
  const downloadHtmlFile = () => {
    // Construct the URL to the file in the assets folder
    const fileUrl = `frontend/src/assets/distributed_template.html`;
    console.log(fileUrl);
    // Create a link element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'distributed_template.html'); // Specify the file name

    // Append the link to the document
    document.body.appendChild(link);
    link.click();

    // Remove the link after downloading
    link.parentNode?.removeChild(link);
  };

  const menu = (
    <Menu>
      <Menu.SubMenu key="1" title="Paylanma Cədvəli">
        {subMenuItems.map((subItem, index) => (
          <Menu.Item
            key={`submenu1-${index}`}
            onClick={() => downloadHtmlFile()}
          >
            {fields?.[subItem]} üzrə
          </Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu key="2" title="Reyistr">
        {subMenuItems.map((subItem, index) => (
          <Menu.Item key={`submenu2-${index}`}>
            {fields?.[subItem]} üzrə
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <Button icon={<MoreOutlined />} />
    </Dropdown>
  );
};

export default ThreeDotMenu;
