import React from 'react';
import { MainLayout } from '../../components/layouts/MainLayout';
import Title from 'antd/es/typography/Title';
import { Tabs, TabsProps, Row } from 'antd';
import SalaryTable from './tabs/SalaryTable';
import withAuth from '../../hocs/withAuth';
import EmployeeTable from './tabs/EmployeeTable';
import StatTable from './tabs/StatTable';
import ThreeDotMenu from './components/ThreeDotMenu';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAllCorpsFields, getMilitarySpace } from '../../api/services';
import { fields } from '../../constants/fields';

type FieldsKey = keyof typeof fields;

const Index: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const onChange = (key: string) => {
    console.log(key);
  };

  const { data: corps, isSuccess: isCorpsLoaded } = useQuery(
    ['GET_MILITARY_SPACE', id],
    () => getMilitarySpace(id!),
    {
      enabled: !!id,
    },
  );

  const { data: corpsField } = useQuery(
    ['CORPS_FIELD', corps?.id],
    () => getAllCorpsFields(corps?.id!),
    {
      enabled: isCorpsLoaded && !!corps?.id,
    },
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Hesabatlar',
      children: <SalaryTable />,
    },
    {
      key: '2',
      label: 'Mülkilər və dövlət qulluqçuları',
      children: <EmployeeTable />,
    },
    {
      key: '3',
      label: 'Hərbi qulluqçular',
      children: <EmployeeTable />,
    },
    {
      key: '4',
      label: 'Statistika',
      children: <StatTable currentFields={corpsField?.fields ?? []} />,
    },
  ];

  return (
    <MainLayout>
      <div>
        <Row justify="space-between" align="middle">
          <Title style={{ margin: '0' }} level={3}>
            1 saylı hərbi hissə
          </Title>
          <ThreeDotMenu
            military={corps}
            subMenuItems={Object.keys(fields).slice(0, 8) as Array<FieldsKey>}
          />
        </Row>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </MainLayout>
  );
};

export default withAuth(Index);
