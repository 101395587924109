import fetch from './interceptor';

export const loginService = async (data: any): Promise<any> => {
  return await fetch.post('/api/auth/login', data);
};

export const getCorps = async (): Promise<any> => {
  return await fetch.get('/api/mock/corps');
};

export const getUser = async (): Promise<any> => {
  return await fetch.get('/api/auth/user');
};

export const getSolidersBySpaceId = async (id: string): Promise<any> => {
  return await fetch.get(`/api/mock/solider?militarySpaceId=${id}`);
};

export const getMilitarySpacedByCorpsId = async (
  corpId: string,
): Promise<any> => {
  return await fetch.get(`/api/mock/corps/${corpId}`);
};

export const getMilitarySpace = async (
  militarySpaceId: string,
): Promise<any> => {
  return await fetch.get(`/api/mock/corps/military-spaces/${militarySpaceId}`);
};

export const getLogs = async (
  pageSize: number,
  currentPage: number,
  dateRange: [string, string] | null,
): Promise<any> => {
  return await fetch.get('/api/log?pageNumber=1&pageSize=20');
};

export const addUser = async (data: any): Promise<any> => {
  return await fetch.post('/api/auth/add-user', data);
};

export const getAllUsers = async (): Promise<any> => {
  return await fetch.get('/api/auth/users');
};

export const deleteUserById = async (id: string): Promise<any> => {
  return await fetch.delete(`/api/auth/user/${id}`);
};

export const updateCorpsFields = async (data: any): Promise<any> => {
  return await fetch.put('/api/corps/add-corps', data);
};

export const getAllCorpsFields = async (id: string): Promise<any> => {
  return await fetch.get(`/api/corps/fields/${id}`);
};

export const getAllSalariesByDate = async (
  corpsId: string,
  month: number,
  year: number,
): Promise<any> => {
  return await fetch.get(
    `/api/salary/salary-record/${corpsId}?month=${month}&year=${year}`,
  );
};

export const getSalaryRecordByDate = async (
  corpsId: string,
  month: number,
  year: number,
  pageSize: number = 10,
  pageNumber: number = 1,
): Promise<any> => {
  return await fetch.get(
    `/api/salary/salary-record/corps/${corpsId}?month=${month}&year=${year}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
  );
};

export const getSalaryById = async (id: string): Promise<any> => {
  return await fetch.get(`/api/salary/salary-record/profile/${id}`);
};

export const downloadReportPdf = async (request: any): Promise<any> => {
  return await fetch.post(`/api/salary/generate-report-pdf`, request, {
    responseType: 'blob',
  });
};

export const downloadIndividualPdf = async (id: string): Promise<any> => {
  return await fetch.post(
    `/api/salary/generate-individual-report-pdf/${id}`,
    {},
    {
      responseType: 'blob',
    },
  );
};

export const moveNextMonth = async (corpsId: string) =>
  await fetch.put(`/api/salary/salary-record/${corpsId}`);

export const getStatistic = async (year: number, key: string) => {
  return await fetch.get(`/api/statistic?year=${year}&key=${key}`);
};

export const getStatisticById = async (
  id: string,
  year: number,
  key: string,
) => {
  return await fetch.get(`/api/statistic/${id}?year=${year}&key=${key}`);
};

export const calculateSalary = async (data: any) =>
  await fetch.put('/api/salary/salary-record/calculate', data);

export const saveSalary = async (data: any) =>
  await fetch.put('/api/salary/salary-record/save', data);
