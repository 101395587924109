import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router';
import { MainLayout } from '../components/layouts/MainLayout';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleBackHome = () => {
    navigate('/'); // Redirects the user to the home page
  };

  return (
    <MainLayout>
      <Result
        status="404"
        title="404"
        subTitle="Üzr istəyirik, belə bir səhifə mövcud deyil."
        extra={
          <Button type="primary" onClick={handleBackHome}>
            Ana səhifəyə qayıt
          </Button>
        }
      />
    </MainLayout>
  );
};

export default NotFound;
