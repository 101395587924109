import React, { useState } from 'react';
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
  Select,
  Flex,
  message,
} from 'antd';
import withAuth from '../hocs/withAuth';
import { PlusOutlined } from '@ant-design/icons';
import { MainLayout } from '../components/layouts/MainLayout';
import Title from 'antd/es/typography/Title';
import { useMutation, useQuery } from 'react-query';
import {
  addUser,
  deleteUserById,
  getAllUsers,
  getCorps,
  getMilitarySpacedByCorpsId,
} from '../api/services';
import { useAuth } from '../hooks/useAuth';

interface SystemUser {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  role: string;
  corpsId?: string;
  militarySpaceId?: string;
}

const AddUser: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { userInfo } = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState<number>(3);
  const [selectedCorps, setSelectedCorps] = useState<string | null>(null);

  const mutation = useMutation(addUser, {
    onSuccess: async () => {
      await messageApi.open({
        type: 'success',
        content: 'Əməliyyat uğurla icra olundu!',
      });
      window.location.reload();
    },
  });

  const deleteMutation = useMutation(deleteUserById, {
    onSuccess: () => window.location.reload(),
  });
  const [form] = Form.useForm();

  const showAddModal = () => {
    setIsModalVisible(true);
    form.resetFields();
  };

  const deleteUser = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id);
    } catch (e: any) {
      messageApi.open({
        type: 'error',
        content: 'Xəta baş verdi!',
      });
    }
  };

  const handleFormSubmit = async (values: SystemUser) => {
    try {
      const payload = {
        userInfo: {
          ...values,
          corpsId:
            selectedRole === 2 || selectedRole === 3 ? values.corpsId : null,
          militarySpaceId: selectedRole === 3 ? values.militarySpaceId : null,
        },
        creator: userInfo()?.nameid,
      };
      await mutation.mutateAsync(payload);
      setIsModalVisible(false);
      form.resetFields();
    } catch (e: any) {
      messageApi.open({
        type: 'error',
        content: 'Xəta baş verdi!',
      });
    }
  };

  const handleRoleChange = (role: number) => {
    setSelectedRole(role);
  };

  const handleCorpsChange = (corpsId: string) => {
    setSelectedCorps(corpsId);
  };

  const columns = [
    { title: 'Ad', dataIndex: 'firstName', key: 'firstName' },
    { title: 'Soyad', dataIndex: 'lastName', key: 'lastName' },
    { title: 'İstifadəçi adı', dataIndex: 'username', key: 'username' },
    { title: 'Rolu', dataIndex: 'role', key: 'role' },
    {
      title: '',
      key: 'actions',
      render: (_: any, record: SystemUser) => (
        <Flex gap={5}>
          <Popconfirm
            okText={'Bəli'}
            cancelText={'Xeyr'}
            title="Silmək istədiyinizi əminsiniz?"
            onConfirm={() => deleteUser(record.id)}
          >
            <Button type="primary" danger>
              Sil
            </Button>
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  const { data: corps } = useQuery('corps', getCorps);
  const { data: users } = useQuery<SystemUser[]>('GET_ALL_USERS', getAllUsers);
  const { data: militarySpaces } = useQuery(
    ['GET_MILITARY_SPACES', selectedCorps],
    () => getMilitarySpacedByCorpsId(selectedCorps!),
    {
      enabled: !!selectedCorps,
      select: (data) => {
        return data[0].militarySpaces;
      },
    },
  );

  return (
    <MainLayout>
      {contextHolder}
      <Flex align="center" justify="space-between" gap={10}>
        <Title level={3}>İstifadəçilər</Title>
        <Button type="primary" icon={<PlusOutlined />} onClick={showAddModal}>
          İstifadəçi yarat
        </Button>
      </Flex>
      <Table dataSource={users} columns={columns} rowKey="username" />
      <Modal
        title={'İstifadəçi əlavə et'}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        okText="Yadda saxla"
        cancelText="Ləğv et"
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          onFinish={handleFormSubmit}
          layout="vertical"
          onValuesChange={(changedValues) => {
            if (changedValues.role) handleRoleChange(changedValues.role);
          }}
        >
          <Form.Item
            name="firstName"
            label="Ad"
            rules={[{ required: true, message: 'Bu xana boş ola bilməz!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Soyad"
            rules={[{ required: true, message: 'Bu xana boş ola bilməz!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="username"
            label="İstifadəçi adı"
            rules={[{ required: true, message: 'Bu xana boş ola bilməz!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="password"
            label="Şifrə"
            rules={[
              { required: true, message: 'Bu xana boş ola bilməz!' },
              {
                min: 6,
                message: 'Şifrə minimum 6 simvol olmalıdır!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="role"
            label="Rolu"
            rules={[{ required: true, message: 'Bu xana boş ola bilməz!' }]}
          >
            <Select onChange={handleRoleChange}>
              <Select.Option value={1}>Admin</Select.Option>
              <Select.Option value={2}>Manager</Select.Option>
              <Select.Option value={3}>User</Select.Option>
            </Select>
          </Form.Item>

          {(selectedRole === 2 || selectedRole === 3) && (
            <Form.Item
              name="corpsId"
              label="Korpus"
              rules={[{ required: true, message: 'Bu xana boş ola bilməz!' }]}
            >
              <Select onChange={handleCorpsChange}>
                {corps?.map((x: any) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {selectedRole === 3 && (
            <Form.Item
              name="militarySpaceId"
              label="Hərbi hissə"
              rules={[{ required: true, message: 'Bu xana boş ola bilməz!' }]}
            >
              <Select>
                {militarySpaces?.map((x: any) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </MainLayout>
  );
};

export default withAuth(AddUser, true);
