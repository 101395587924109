import Cookie from '../utils/Cookie';
import { jwtDecode } from 'jwt-decode';

interface IUserInfo {
  role: string;
  unique_name: string;
  nameid: string;
}

export const useAuth = () => {
  const userInfo = (): IUserInfo | null => {
    const token = Cookie.getCookie('token')!;
    return token ? jwtDecode(token) : null;
  };

  return { userInfo };
};
