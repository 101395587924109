import React from 'react';
import { useParams } from 'react-router-dom';
import SortableTable from '../../../components/main/SortableTable';
import { Column } from 'react-table';
import withAuth from '../../../hocs/withAuth';

interface UserData {
  name: string;
  fin: string;
  rank: string;
  birthDate: string;
  serviceYears: number;
}

const columns: Column<UserData>[] = [
  {
    Header: 'Ad Soyad Ata adı',
    accessor: 'name',
  },
  {
    Header: 'FIN',
    accessor: 'fin',
  },
  {
    Header: 'Doğum tarixi',
    accessor: 'birthDate',
  },
  {
    Header: 'Rütbə',
    accessor: 'rank',
  },
  {
    Header: 'Xidmət illəri',
    accessor: 'serviceYears',
  },
];

// Fake constant data
const fakeUserData: UserData[] = [
  {
    name: 'Ali Vəliyev',
    fin: 'FIN12345',
    rank: 'Kapitan',
    birthDate: '1990-05-15',
    serviceYears: 10,
  },
  {
    name: 'Əhməd Quliyev',
    fin: 'FIN67890',
    rank: 'Mayor',
    birthDate: '1985-09-20',
    serviceYears: 15,
  },
  {
    name: 'Zeynəb Əliyeva',
    fin: 'FIN11223',
    rank: 'Leytenant',
    birthDate: '1995-12-10',
    serviceYears: 5,
  },
];

const EmployeeTable = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <SortableTable columns={columns} data={fakeUserData} />
    </>
  );
};

export default withAuth(EmployeeTable);
