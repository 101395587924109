import React from 'react';
import { MainLayout } from '../components/layouts/MainLayout';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { Button, Col, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import withAuth from '../hocs/withAuth';
import { fieldArray } from '../constants/fields';
import { useQuery } from 'react-query';
import { downloadIndividualPdf, getSalaryById } from '../api/services';
import { DateFormatter } from '../utils/DateFormatter';

// Mock Data
const mockData = {
  fullName: 'Ali Vəliyev',
  createdAt: new Date().toISOString(),
  corpsFieldFields: ['field1', 'field2', 'field3'], // Example keys
  salaryCalculation: {
    RankSalary: 55,
    PositionSalary: 395,
    given: 450,
    taken: 50,
    totalSalary: 400,
  },
};

const CalculationProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const goBack = () => {
    navigate(-1);
  };

  const goEditProfile = () => {
    navigate('edit');
  };

  const useMockData = true; // Toggle between mock and real data

  const { data = useMockData ? mockData : {} } = useQuery(
    ['FETCH_SALARY_INFO', id],
    () => getSalaryById(id!),
    {
      enabled: !!id && !useMockData,
    },
  );

  const downloadFile = async () => {
    const response = await downloadIndividualPdf(id!);

    const file = new File([response], 'çıxarış.pdf', {
      type: 'application/pdf',
    });

    const url = window.URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'çıxarış.pdf'); // Specify the file name

    document.body.appendChild(link);
    link.click();

    link.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <MainLayout>
      <div>
        <Button onClick={goBack}>Siyahıya qayıt</Button>
        <Row
          justify="space-between"
          align={'top'}
          style={{ marginTop: '16px' }}
        >
          <Title level={3} style={{ margin: 0 }}>
            {data?.fullName} -{' '}
            <b>{DateFormatter.formatDate(data?.createdAt)}</b>
          </Title>
          <div>
            <Button onClick={downloadFile} style={{ marginRight: '16px' }}>
              Çıxarış et
            </Button>
            <Button
              onClick={goEditProfile}
              style={{ marginRight: '16px' }}
              type="primary"
            >
              <EditOutlined />
            </Button>
          </div>
        </Row>
      </div>
      <div>
        <Row gutter={16} style={{ marginTop: '16px' }}>
          {fieldArray.map((field) => (
            <Col span={8} key={field.key}>
              <Title level={5}>
                {field.label}:{' '}
                <Text style={{ fontWeight: 400 }}>
                  {data.salaryCalculation?.[field.key]
                    ? data.salaryCalculation?.[field.key]
                    : 0}{' '}
                  AZN
                </Text>
              </Title>
            </Col>
          ))}
        </Row>
        <hr />
        <Row justify="space-between">
          <Title level={5}>
            Ümumi verilir:{' '}
            <Text style={{ fontWeight: 400 }}>
              {data.salaryCalculation?.given} AZN
            </Text>
          </Title>
          <Title level={5}>
            Ümumi Tutulur:{' '}
            <Text style={{ fontWeight: 400 }}>
              {data.salaryCalculation?.taken} AZN
            </Text>
          </Title>
          <Title level={5}>
            Maaş:{' '}
            <Text style={{ fontWeight: 400 }}>
              {data.salaryCalculation?.totalSalary} AZN
            </Text>
          </Title>
        </Row>
      </div>
    </MainLayout>
  );
};

export default withAuth(CalculationProfile);
