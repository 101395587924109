import { Select } from 'antd';
import React from 'react';

type Option = {
  value: number | string;
  label: string;
};

interface IPropOptions {
  options: Option[];
  onChange: (value: number | string) => void;
  defaultValue: string;
}

export const CalculationOptions = (props: Partial<IPropOptions>) => {
  return (
    <Select
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      placeholder={'Seçin'}
      style={{ width: '50%' }}
    >
      {props.options?.map((option: Option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};
