export const MONTHS = [
  { id: 1, name: 'Yanvar' },
  { id: 2, name: 'Fevral' },
  { id: 3, name: 'Mart' },
  { id: 4, name: 'Aprel' },
  { id: 5, name: 'May' },
  { id: 6, name: 'İyun' },
  { id: 7, name: 'İyul' },
  { id: 8, name: 'Avqust' },
  { id: 9, name: 'Sentyabr' },
  { id: 10, name: 'Oktyabr' },
  { id: 11, name: 'Noyabr' },
  { id: 12, name: 'Dekabr' },
];

export const monthNames = [
  'Yanvar',
  'Fevral',
  'Mart',
  'Aprel',
  'May',
  'İyun',
  'İyul',
  'Avqust',
  'Sentyabr',
  'Oktyabr',
  'Noyabr',
  'Dekabr',
];
