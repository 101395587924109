import React, { useEffect } from 'react';
import { Row, Col, Form, Input, Button, Typography } from 'antd';
import { MainLayout } from '../components/layouts/MainLayout';
import { useQuery } from 'react-query';
import { getUser } from '../api/services';
import withAuth from '../hocs/withAuth';

const { Title, Text } = Typography;

const Profile: React.FC = () => {
  const [form] = Form.useForm();
  const { data: user } = useQuery('userGet', getUser);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        fullName: `${user.firstName} ${user.lastName}`,
        rank: user.rank,
        username: user.username,
      });
    }
  }, [user]);

  return (
    <MainLayout>
      <Title level={2}>{user?.role}</Title>
      <Row gutter={16}>
        <Col xs={24} sm={16}>
          <Form layout="vertical" form={form}>
            <Title level={5}>Şəxsi Məlumatlar</Title>
            <Form.Item label="Ad Soyad" name="fullName">
              <Input />
            </Form.Item>
            <Form.Item label="Rütbə" name="rank">
              <Input />
            </Form.Item>
            <Form.Item label="E-mail" name="username">
              <Input type="text" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Yadda saxla
              </Button>
            </Form.Item>

            <Title level={5}>Şifrəni dəyişdirilməsi</Title>
            <Form.Item label="Köhnə şifrə" name="currentPassword">
              <Input.Password />
            </Form.Item>
            <Form.Item label="Təzə şifrə" name="newPassword">
              <Input.Password />
            </Form.Item>
            <Form.Item label="Şifrənin təkrarı" name="confirmPassword">
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Şifrəni dəyiş
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default withAuth(Profile);
