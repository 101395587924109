import React, { useState } from 'react';
import { MainLayout } from '../components/layouts/MainLayout';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { Button, Checkbox, Col, Form, message, Row, Select } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { getCorps, updateCorpsFields } from '../api/services';
import withAuth from '../hocs/withAuth';
import { fieldArray } from '../constants/fields';
import { CORPS } from '../constants/corps';

const Settings: React.FC = () => {
  const [selectedCheckbox, setSelectedCheckbox] = useState<Array<string>>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedCorps, setSelectedCorps] = useState<string>('');
  const [currentId] = useState<string>('');

  const handleChange = (e: any) => {
    const item = e.target.value;
    setSelectedCheckbox((prev) =>
      prev.includes(item) ? prev.filter((x) => x !== item) : [...prev, item],
    );
  };

  const { data: corpsOptions = CORPS, isLoading: isCorpsLoading } = useQuery(
    'corps',
    getCorps,
  );

  const { mutateAsync, isLoading: isUpdating } = useMutation(updateCorpsFields);

  // const { isLoading: isFieldsLoading, isError } = useQuery(
  //   ['CORPS_FIELDS', selectedCorps],
  //   () => getAllCorpsFields(selectedCorps),
  //   {
  //     enabled: !!selectedCorps,
  //     onSuccess: (data) => {
  //       setSelectedCheckbox(data ? data.fields : []);
  //       setCurrentId(data ? data.id : '');
  //     },
  //   },
  // );

  const handleSubmit = async (values: any) => {
    try {
      const requestBody = {
        id: currentId || undefined,
        corpsId: values?.corps || null,
        fields: selectedCheckbox,
      };

      await mutateAsync(requestBody);
      messageApi.success('Tənzimləmələr yadda saxlanıldı!');
    } catch (error) {
      console.error('Error submitting data:', error);
      messageApi.error('Xəta baş verdi, lütfən yenidən cəhd edin.');
    }
  };

  // Group fields by group name
  const groupedFields = fieldArray.reduce((acc: any, field) => {
    if (!acc[field.group]) {
      acc[field.group] = [];
    }
    acc[field.group].push(field);
    return acc;
  }, {});

  return (
    <MainLayout>
      {contextHolder}
      <Title level={4}>Tənzimləmələr</Title>
      <Text>Hesablama düsturlarını seçin:</Text>

      <div style={{ padding: '20px 0' }}>
        <Form onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="corps"
            label="Qurum Seçin"
            rules={[
              { required: true, message: 'Zəhmət olmasa bir qurum seçin!' },
            ]}
          >
            <Select
              defaultValue={'Seçin'}
              value={selectedCorps}
              onChange={setSelectedCorps}
              style={{ marginBottom: '20px', width: '50%' }}
            >
              {corpsOptions?.map((x: any) => (
                <Select.Option value={x.id} key={x.id}>
                  {x.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {Object.entries(groupedFields).map(([groupName, list]: any) => (
            <div key={groupName} style={{ marginBottom: '20px' }}>
              <Title level={5}>{groupName}</Title>
              <Row gutter={16}>
                {list?.map((field: any) => (
                  <Col span={6} key={field.key}>
                    <Checkbox
                      checked={selectedCheckbox.includes(field.key)}
                      value={field.key}
                      onChange={handleChange}
                    >
                      {field.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </div>
          ))}

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isUpdating}>
              Yadda saxla
            </Button>
          </Form.Item>
        </Form>
      </div>
    </MainLayout>
  );
};

export default withAuth(Settings, true);
