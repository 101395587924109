export const skillOptions = [
  {
    label: 'Verilmir',
    value: 0,
  },
  {
    label: 'Usta məharət dərəcəsinə görə',
    value: 1,
  },
  {
    label: '1-ci məharət dərəcəsinə görə',
    value: 2,
  },
  {
    label: '2-ci məharət dərəcəsinə görə',
    value: 3,
  },
];

export const confidentialOptions = [
  {
    label: 'Verilmir',
    value: 0,
  },
  {
    label: '10%',
    value: 10,
  },
  {
    label: '15%',
    value: 15,
  },
  {
    label: '20%',
    value: 20,
  },
];

export const languageOptions = [
  { label: 'Verilmir', value: 0 },
  { label: 'Avropa dillərinə görə', value: 10 },
  { label: 'Şərq dillərinə görə', value: 15 },
];

export const borderServicePresOptions = [
  {
    label: 'Verilmir',
    value: 0,
  },
  {
    label: '35%',
    value: 35,
  },
  {
    label: '40%',
    value: 40,
  },
  {
    label: '45%',
    value: 45,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '55%',
    value: 55,
  },
  {
    label: '60%',
    value: 60,
  },
];

export const liberatedAreasPresOptions = [
  {
    label: 'Verilmir',
    value: 0,
  },
  {
    label: '20%',
    value: 20,
  },
  {
    label: '22.5%',
    value: 22.5,
  },
  {
    label: '25%',
    value: 25,
  },
  {
    label: '27.5%',
    value: 27.5,
  },
  {
    label: '30%',
    value: 30,
  },
];

export const specialForcesPercentageOptions = [
  {
    label: 'Verilmir',
    value: 0,
  },
  {
    label: '30%',
    value: 30,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '80%',
    value: 80,
  },
];

export const specialForcesOptions = [
  {
    label: 'Verilmir',
    value: 0,
  },
  {
    label:
      'Tankların, özüyeriyən artilleriya qurğularının, PDM-lərin, tank şassiləri üzərində özüyeriyən zenit qurğularının, DKDM-lərin və özüyeriyən haubitsaların ekipajlarına, ZRK-ların özüyeriyən buraxma qurğularının və bu komplekslərin aşkarlama və yönəltmə özüyeriyən stansiyalarının heyətlərində xidmət edənlər',
    value: 20,
  },
  {
    label:
      'Üzən, təmirdə və konservasiyada olan gəmilərin ekipajlarında, gəmilərdə müəyyən edilmiş qaydada daimi yerləşdirilmiş birləşmələrin idarə heyətlərində xidmət edənlər',
    value: 15,
  },
  {
    label:
      'Aviatsiya hərbi hissələrində uçuşların təhlükəsizliyini təmin edən aviasiya mütəxəssisləri vəzifələrində xidmət edənlər',
    value: 15,
  },
  {
    label:
      'Uçuş heyəti vəzifələrində xidmət edən və döyüş hazırlığı planlarına əsasən uçuşlar həyata keçirənlər',
    value: 30,
  },
  {
    label:
      'Hərbi tibb hissələri, müəssisələri və bölmələrində sağlamlıq üçün təhlükəli və ağır iş şəraiti olan hərbi qulluqçular',
    value: 15,
  },
  {
    label:
      'Hərbi tibb hissələrində xüsusi olaraq sağlamlıq üçün təhlükəli və ağır iş şəraiti olan hərbi qulluqçular',
    value: 20,
  },
  {
    label:
      'İİV virusuna yoluxmuş və yoluxucu xəstəliklərə malik şəxslərə tibbi yardım göstərən, məhkəmə-tibbi və məhkəmə-psixiatriya ekspertizası aparan həkimlər, orta və kiçik tibb heyəti vəzifələrində xidmət edənlər',
    value: 60,
  },
];

export const highMountainPercentageOptions = [
  {
    label: 'Daniz saviyyesinden 00 metrden 1500 metradek oldugda',
    value: 10,
  },
  {
    label: 'Daniz saviyyesinden 1500 metrden 2000 metradek oldugda',
    value: 20,
  },
  {
    label: 'Daniz seviyyesinden 2000 metrden yuxari olduqda',
    value: 30,
  },
];

export const ranks = [
  {
    label: 'Əsgər',
    value: 80.0,
  },
  {
    label: 'Kiçik çavuş',
    value: 95.0,
  },
  {
    label: 'Çavuş',
    value: 100.0,
  },
  {
    label: 'Baş çavuş',
    value: 102.0,
  },
  {
    label: 'Baş əsgər',
    value: 85.0,
  },
  {
    label: 'Gizir',
    value: 108.0,
  },
  {
    label: 'General-Polkovnik',
    value: 165.0,
  },
  {
    label: 'General-Leytenant',
    value: 153.0,
  },
  {
    label: 'General-Mayor',
    value: 145.0,
  },
  {
    label: 'Kiçik gizir',
    value: 106.0,
  },
  {
    label: 'Polkovnik-leytenant',
    value: 134.0,
  },
  {
    label: 'Mayor',
    value: 130.0,
  },
  {
    label: 'Polkovnik',
    value: 138.0,
  },
  {
    label: 'Baş leytenant',
    value: 125.0,
  },
  {
    label: 'Leytenant',
    value: 122.0,
  },
  {
    label: 'Kiçik leytenant',
    value: 120.0,
  },
  {
    label: 'Baş gizir',
    value: 110.0,
  },
  {
    label: 'Kapitan',
    value: 127.0,
  },
  {
    label: 'Ordu general',
    value: 177.0,
  },
];

export const honorTitleOptions = [
  { label: 'Verilmir', value: 0 },
  { label: 'əməkdar elm xadimi', value: 60 },
  { label: 'əməkdar incəsənət xadimi', value: 60 },
  { label: 'əməkdar artist', value: 60 },
  { label: 'əməkdar rəssam', value: 60 },
  { label: 'əməkdar mədəniyyət işçisi', value: 60 },
  { label: 'əməkdar müəllim', value: 60 },
  { label: 'əməkdar həkim', value: 60 },
  { label: 'əməkdar bədən tərbiyəsi və idman xadimi', value: 60 },
  { label: 'əməkdar dövlət qulluqçusu', value: 60 },
  { label: 'əməkdar jurnalist', value: 60 },
  { label: 'əməkdar mühəndis', value: 60 },
  { label: 'əməkdar hüquqşünas', value: 60 },
  { label: 'əməkdar kənd təsərrüfatı işçisi', value: 60 },
  { label: 'əməkdar pilot', value: 60 },
  { label: 'əməkdar tibb işçisi', value: 60 },
  { label: 'xalq yazıçısı', value: 100 },
  { label: 'xalq şairi', value: 100 },
  { label: 'xalq artisti', value: 100 },
  { label: 'xalq rəssamı', value: 100 },
];

export const cryptedOptions = [
  { value: 0, label: 'Verilmir' },
  { value: 10, label: '10%' },
  { value: 15, label: '15%' },
  { value: 20, label: '20%' },
];

export const navalOptions = [
  {
    label: 'Verilmir',
    value: 0,
  },
  {
    label: 'Daxili üzmə',
    value: 30,
  },
  {
    label: 'Xarici üzmə',
    value: 50,
  },
];
