import React, { useState } from 'react';
import { MainLayout } from '../components/layouts/MainLayout';
import Title from 'antd/es/typography/Title';
import { Button, Col, Flex, Row, Form, Checkbox, Input, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import withAuth from '../hocs/withAuth';
import { CalculationNumberInput } from '../components/main/CalculationNumberInput';
import { CalculationOptions } from '../components/main/CalculationOptions';
import Text from 'antd/es/typography/Text';
import { useMutation, useQuery } from 'react-query';
import { calculateSalary, getSalaryById, saveSalary } from '../api/services';
import { DateFormatter } from '../utils/DateFormatter';
import { Loading } from '../components/main/Loading';
import {
  borderServicePresOptions,
  confidentialOptions,
  cryptedOptions,
  highMountainPercentageOptions,
  honorTitleOptions,
  languageOptions,
  liberatedAreasPresOptions,
  navalOptions,
  ranks,
  skillOptions,
  specialForcesOptions,
  specialForcesPercentageOptions,
} from '../constants/calculationOptions';

const CalculationEditProfile = () => {
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const goBack = () => {
    navigate(-1);
  };

  const { id } = useParams();

  const [salaryInfo, setSalaryInfo] = useState<any>({});

  const { isLoading, isError } = useQuery(
    ['FETCH_EDIT_SALARY_INFO', id],
    () => getSalaryById(id!),
    {
      enabled: !!id,
      onSuccess: (data: any) => {
        setSalaryInfo(data);
      },
    },
  );

  const { mutate, isLoading: calculationLoading } = useMutation(
    calculateSalary,
    {
      onSuccess: (data) => {
        setSalaryInfo(data);
      },
    },
  );

  const handleDetailCalculation = (name: any, value: any) => {
    let finalValue = value;
    if (value?.target) {
      finalValue = value?.target?.checked;
    }

    setSalaryInfo((prevSalary: any) => ({
      ...prevSalary,
      salaryDetail: {
        ...prevSalary.salaryDetail,
        [name]: finalValue,
      },
    }));
  };

  const handleCalculation = (name: any, value: any) => {
    setSalaryInfo((prevSalary: any) => ({
      ...prevSalary,
      salaryCalculation: {
        ...prevSalary.salaryCalculation,
        [name]: value,
      },
    }));
  };

  const { mutate: updateSalary, isLoading: updateLoading } = useMutation(
    saveSalary,
    {
      onSuccess: (data: any) => {
        messageApi.open({
          type: 'success',
          content: 'Əməliyyat uğurla icra olundu!',
        });
        setSalaryInfo(data);
      },
      onError: (err: any) => {
        messageApi.open({
          type: 'error',
          content: err.message,
        });
      },
    },
  );

  const calculate = () => {
    mutate(salaryInfo);
  };

  const save = () => {
    updateSalary(salaryInfo);
  };

  return (
    <MainLayout>
      {contextHolder}
      <div>
        <Flex
          justify="space-between"
          align={'start'}
          style={{ marginTop: '16px' }}
        >
          <Title level={3} style={{ margin: 0 }}>
            {salaryInfo.fullName} -{' '}
            <b>{DateFormatter.formatDate(salaryInfo?.createdAt)}</b>{' '}
          </Title>
          <div>
            <Button onClick={goBack} style={{ marginRight: '16px' }}>
              Ləğv et
            </Button>
            <Button
              onClick={calculate}
              loading={calculationLoading}
              style={{ marginRight: '16px' }}
              type="primary"
            >
              Hesabla
            </Button>
            <Button
              onClick={save}
              loading={updateLoading}
              style={{ marginRight: '16px' }}
              type="primary"
            >
              Yadda Saxla
            </Button>
          </div>
        </Flex>
      </div>
      <div>
        <Loading
          isLoading={isLoading || calculationLoading || updateLoading}
          isHasError={isError}
        >
          <Form layout={'vertical'}>
            <Row gutter={16} style={{ marginTop: '8px' }}>
              <Col span={8}>
                <Form.Item label={<Title level={5}>Hərbi rütbə maaşı: </Title>}>
                  <Flex>
                    <CalculationOptions
                      options={ranks}
                      defaultValue={salaryInfo?.rank}
                      onChange={(e) => handleCalculation('rankSalary', e)}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      disabled
                      value={salaryInfo?.salaryCalculation?.rankSalary}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Vəzifə maaşı: </Title>}>
                  <CalculationNumberInput
                    onChange={(e: any) =>
                      handleCalculation('positionSalary', e)
                    }
                    value={salaryInfo?.salaryCalculation?.positionSalary}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      Xidmət ili:{' '}
                      {salaryInfo?.salaryDetail?.yearsOfServiceBonus} il{' '}
                    </Title>
                  }
                >
                  <CalculationNumberInput
                    defaultValue={
                      salaryInfo?.salaryCalculation?.serviceYearBonus
                    }
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Məharət dərəcəsi: </Title>}>
                  <Flex>
                    <CalculationOptions
                      defaultValue={salaryInfo?.salaryDetail?.skillDegreeBonus}
                      onChange={(e) =>
                        handleDetailCalculation('skillDegreeBonus', e)
                      }
                      options={skillOptions}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      disabled={true}
                      defaultValue={salaryInfo?.salaryCalculation?.skillBonus}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Təmsilçilik xərci: </Title>}>
                  <Flex>
                    <Checkbox
                      checked={salaryInfo?.salaryDetail?.isRepresentationBonus}
                      onChange={(e) =>
                        handleDetailCalculation('isRepresentationBonus', e)
                      }
                    >
                      verilir
                    </Checkbox>
                    {/* 25 */}
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.representationBonus
                      }
                      disabled={true}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Zərərlik: </Title>}>
                  <Flex>
                    <CalculationOptions options={[]} />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Məxfilik əlavəsi: </Title>}>
                  <Flex>
                    <CalculationOptions
                      defaultValue={
                        salaryInfo?.salaryDetail?.confidentialityBonus
                      }
                      options={confidentialOptions}
                      onChange={(e) =>
                        handleDetailCalculation('confidentialityBonus', e)
                      }
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      disabled={true}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.confidentialityBonus
                      }
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={<Title level={5}>Xarici dil əlavəsi: </Title>}
                >
                  <Flex>
                    <CalculationOptions
                      defaultValue={
                        salaryInfo?.salaryDetail?.foreignLanguageBonus
                      }
                      options={languageOptions}
                      onChange={(e) =>
                        handleDetailCalculation('foreignLanguageBonus', e)
                      }
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.foreignLanguageBonus
                      }
                      disabled={true}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Kəş.mük. : </Title>}>
                  <Flex>
                    <Checkbox>verilir</Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={<Title level={5}>Elmi dərəcəsinə görə əlavə: </Title>}
                >
                  <Flex>
                    <CalculationOptions options={[]} />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={<Title level={5}>Kibertəhlükəsizliyi əlavəsi: </Title>}
                >
                  <Flex>
                    <CalculationNumberInput
                      inputType={'percentage'}
                      value={salaryInfo?.salaryDetail?.cyberSecurityBonus}
                      onChange={(e: any) =>
                        handleDetailCalculation('cyberSecurityBonus', e)
                      }
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.cyberSecurityBonus
                      }
                      disabled={true}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={<Title level={5}>Fəxri ada görə əlavə: </Title>}
                >
                  <Flex>
                    <CalculationOptions
                      options={honorTitleOptions}
                      defaultValue={
                        salaryInfo?.salaryDetail?.honoraryTitleBonus
                      }
                      onChange={(e) => handleCalculation('honorBonus', e)}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={salaryInfo?.salaryCalculation?.HonorBonus}
                      disabled={true}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Əlavə ödən.(gvt): </Title>}>
                  <Flex>
                    <Checkbox>verilir</Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Ərzaq paylanması: </Title>}>
                  <Flex>
                    <Checkbox>verilir</Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>Mənzil kirayəsi kompensasiya: </Title>
                  }
                >
                  <Flex>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      inputType={'percentage'}
                      defaultValue={20}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      inputType={'percentage'}
                      defaultValue={20}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      inputType={'percentage'}
                      defaultValue={20}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Maddi yardim: </Title>}>
                  <Flex>
                    <Checkbox>verilir</Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Məzuniyyət: </Title>}>
                  <Flex>
                    <Checkbox>verilir</Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Çıxış müavinatı: </Title>}>
                  <Flex>
                    <Checkbox>verilir</Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Ezamiyyet: </Title>}>
                  <Flex>
                    <Checkbox>verilir</Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Səhra pulu: </Title>}>
                  <Flex>
                    <Checkbox
                      checked={salaryInfo?.salaryDetail?.isDesertBonus}
                      onChange={(e) =>
                        handleDetailCalculation('isDesertBonus', e)
                      }
                    >
                      verilir
                    </Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={salaryInfo?.salaryCalculation?.DesertBonus}
                      disabled={true}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Yol xərci: </Title>}>
                  <Flex>
                    <Checkbox>verilir</Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      Ermənistanla şərti dövlət sərhəddində xidmətinə görə
                      əlavə:{' '}
                    </Title>
                  }
                >
                  <Flex>
                    <Checkbox
                      checked={salaryInfo?.salaryDetail?.isBorderServiceBonus}
                      onChange={(e) =>
                        handleDetailCalculation('isBorderServiceBonus', e)
                      }
                    >
                      verilir
                    </Checkbox>
                    {/* vezife 100*/}
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      disabled={true}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.borderServiceBonus
                      }
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      İşğaldan azad edilmiş ərazilərdə xidmətinə görə əlavə:
                    </Title>
                  }
                >
                  <Flex>
                    <Checkbox
                      checked={salaryInfo?.salaryDetail?.isLiberatedAreasBonus}
                      onChange={(e) =>
                        handleDetailCalculation('isLiberatedAreasBonus', e)
                      }
                    >
                      verilir
                    </Checkbox>
                    {/* vezife 100*/}
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.LiberatedAreasBonus
                      }
                      disabled={true}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      Ermənistanla şərti dövlət sərhəddində xidmətinə görə
                      Prezident əlavəsi:
                    </Title>
                  }
                >
                  <Flex>
                    <CalculationOptions
                      defaultValue={
                        salaryInfo?.salaryDetail?.borderServicePresPercentage
                      }
                      onChange={(e) =>
                        handleDetailCalculation(
                          'borderServicePresPercentage',
                          e,
                        )
                      }
                      options={borderServicePresOptions}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.borderServicePresBonus
                      }
                      disabled={true}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      İşğaldan azad edilmiş ərazilərdə xidmətinə görə Prezident
                      əlavəsi
                    </Title>
                  }
                >
                  <Flex>
                    <CalculationOptions
                      defaultValue={
                        salaryInfo?.salaryDetail?.liberatedAreasPresPercentage
                      }
                      onChange={(e) =>
                        handleDetailCalculation(
                          'liberatedAreasPresPercentage',
                          e,
                        )
                      }
                      options={liberatedAreasPresOptions}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.liberatedAreasPresBonus
                      }
                      disabled={true}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      Əlahiddə Ümumqoşun Ordu tərkibində xüsusi təyinatlı hərbi
                      qulluqçular üçün əlavə
                    </Title>
                  }
                >
                  <Flex>
                    <CalculationOptions
                      defaultValue={
                        salaryInfo?.salaryDetail?.specialForcesPercentage
                      }
                      onChange={(e) =>
                        handleDetailCalculation('specialForcesPercentage', e)
                      }
                      options={specialForcesPercentageOptions}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.specialForcesBonus
                      }
                      disabled={true}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Prezidentin əlavəsi</Title>}>
                  <Flex>
                    <Checkbox
                      checked={salaryInfo?.salaryDetail?.isPresidentialBonus}
                      onChange={(e) =>
                        handleDetailCalculation('isPresidentialBonus', e)
                      }
                    >
                      verilir
                    </Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.presidentialBonus
                      }
                      disabled={true}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      Xidmətin xüsusi şəraitinə görə əlavələr
                    </Title>
                  }
                >
                  <Flex>
                    <CalculationOptions
                      defaultValue={
                        salaryInfo?.salaryDetail?.specialConditionsPercentage
                      }
                      onChange={(e) =>
                        handleDetailCalculation(
                          'specialConditionsPercentage',
                          e,
                        )
                      }
                      options={specialForcesOptions}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.specialConditionsBonus
                      }
                      disabled={true}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>Yüksək dağlıq ərazilərə görə əlavə</Title>
                  }
                >
                  <Flex>
                    <CalculationOptions
                      defaultValue={
                        salaryInfo?.salaryDetail?.highMountainPercentage
                      }
                      onChange={(e) =>
                        handleDetailCalculation('highMountainPercentage', e)
                      }
                      options={highMountainPercentageOptions}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={salaryInfo?.salaryDetail?.highMountainBonus}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Şifrələmə əlavəsi</Title>}>
                  <Flex>
                    <CalculationOptions
                      defaultValue={
                        salaryInfo?.salaryDetail?.encryptionPercentage
                      }
                      onChange={(e) =>
                        handleDetailCalculation('encryptionPercentage', e)
                      }
                      options={cryptedOptions}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.encryptedBonus
                      }
                      disabled
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      Təlim və yoxlama toplanışlarda gündəlik ödəniş
                    </Title>
                  }
                >
                  <Flex>
                    <Input placeholder={'Günlərin sayı'} />
                    {/* 20 her gune*/}
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Dəniz pul təminatı</Title>}>
                  <Flex>
                    <CalculationOptions
                      defaultValue={
                        salaryInfo?.salaryDetail?.navalServicePercentage
                      }
                      onChange={(e) =>
                        handleDetailCalculation('navalServicePercentage', e)
                      }
                      options={navalOptions}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.navalServiceBonus
                      }
                      disabled
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={<Title level={5}>Döyüş növbəsinə görə mükafat</Title>}
                >
                  <Flex>
                    <CalculationNumberInput title={''} />
                    <CalculationOptions
                      options={[
                        {
                          label: 'gece növbatciliyine göre',
                          value: '100',
                        },
                        {
                          label: 'gündüz növbatgiliyine göra',
                          value: '50',
                        },
                        {
                          label:
                            'hava hücumundan müdafia qüvvalerinin döyüs növbatciliyina\n' +
                            'calb edilan saxsi heyati',
                          value: '30',
                        },
                      ]}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      Səfərbərlik əsasında h. ödənilən müavinat
                    </Title>
                  }
                >
                  <Flex>
                    <Checkbox
                      checked={
                        salaryInfo?.salaryDetail?.isMobilizationCompensation
                      }
                      onChange={(e) =>
                        handleDetailCalculation('isMobilizationCompensation', e)
                      }
                    >
                      verilir
                    </Checkbox>
                    {/* 360 */}
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation?.mobilizationCompensation
                      }
                      disabled
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      Dövlətlərarası müqavilə əsaslı ödənişlər
                    </Title>
                  }
                >
                  <Flex>
                    <Checkbox
                      checked={
                        salaryInfo?.salaryDetail
                          ?.isInternationalContractPayments
                      }
                      onChange={(e) =>
                        handleDetailCalculation(
                          'isInternationalContractPayments',
                          e,
                        )
                      }
                    >
                      verilir
                    </Checkbox>
                    {/*75*/}
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation
                          ?.internationalContractPayments
                      }
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={<Title level={5}>XTQ üzrə artım əmsalı</Title>}
                >
                  <Flex>
                    <CalculationNumberInput
                      value={
                        salaryInfo?.salaryCalculation
                          ?.specialForceAdditionalBonus
                      }
                      onChange={(e: any) =>
                        handleCalculation('specialForceAdditionalBonus', e)
                      }
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={<Title level={5}>Paraşutla tullanma mükafatı</Title>}
                >
                  <Flex>
                    <Checkbox>verilir</Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={<Title level={5}>Dalğıc işləri üzrə mükafat</Title>}
                >
                  <Flex>
                    <Checkbox>verilir</Checkbox>
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={0}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      Gizirlərə h. ilkin ləvazimat almaq üçün verilən müavinat
                    </Title>
                  }
                >
                  <Flex>
                    <CalculationOptions
                      defaultValue={
                        salaryInfo?.salaryDetail?.nonComOfficerCompensation
                      }
                      onChange={(e) =>
                        handleDetailCalculation('nonComOfficerCompensation', e)
                      }
                      options={[
                        {
                          label: 'Gizir kimi xidmətə qəbul olanlara',
                          value: 2,
                        },
                        {
                          label: 'MAHHXHQ kimi xidmətə qəbul olanlara',
                          value: 1,
                        },
                      ]}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation
                          ?.nonComOfficerStandardBonus
                      }
                      disabled
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      Müddətli h. təxris olunarkən birdəfəlik müavinat
                    </Title>
                  }
                >
                  <Flex>
                    <Checkbox
                      checked={
                        salaryInfo?.salaryDetail
                          ?.isConscriptSeveranceCompensation
                      }
                      onChange={(e) =>
                        handleDetailCalculation(
                          'isConscriptSeveranceCompensation',
                          e,
                        )
                      }
                    >
                      verilir
                    </Checkbox>
                    {/*2 misli*/}
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation
                          ?.conscriptSeveranceCompensation
                      }
                      disabled
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={
                    <Title level={5}>
                      Zabitlərə. təxris olunarkən birdəfəlik müavinat
                    </Title>
                  }
                >
                  <Flex>
                    <CalculationOptions
                      defaultValue={
                        salaryInfo?.salaryDetail?.officerSeveranceTimes
                      }
                      onChange={(e) =>
                        handleDetailCalculation('officerSeveranceTimes', e)
                      }
                      options={[
                        {
                          label: 'Yaşa, xəstəliyə və ştatların ixtisarına görə',
                          value: 5,
                        },
                        {
                          label: 'Digər səbəblərə görə',
                          value: 2,
                        },
                      ]}
                    />
                    <CalculationNumberInput
                      style={{ marginLeft: '12px' }}
                      defaultValue={
                        salaryInfo?.salaryCalculation
                          ?.officerSeveranceCompensation
                      }
                    />
                  </Flex>
                </Form.Item>
              </Col>
            </Row>

            <Title level={4}>Tutulur</Title>
            <Row gutter={16} style={{ marginTop: '8px' }}>
              <Col span={8}>
                <Form.Item label={<Title level={5}>Gəlir vergisi: </Title>}>
                  <Flex>
                    <CalculationNumberInput
                      defaultValue={salaryInfo?.salaryCalculation?.tax}
                      disabled
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>DSMF: </Title>}>
                  <Flex>
                    <CalculationNumberInput
                      disabled
                      defaultValue={salaryInfo?.salaryCalculation?.dsmf}
                    />
                  </Flex>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={<Title level={5}>Tibbi sığorta: </Title>}>
                  <Flex>
                    <CalculationNumberInput
                      disabled
                      defaultValue={salaryInfo?.salaryCalculation?.insurance}
                    />
                  </Flex>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <hr />
          <Flex justify="space-between">
            <Title level={5}>
              Ümumi verilir:{' '}
              <Text style={{ fontWeight: 400 }}>
                {salaryInfo?.salaryCalculation?.given} AZN
              </Text>
            </Title>
            <Title level={5}>
              Ümumi Tutulur:{' '}
              <Text style={{ fontWeight: 400 }}>
                {salaryInfo?.salaryCalculation?.taken} AZN
              </Text>
            </Title>
            <Title level={5}>
              Maaş:{' '}
              <Text style={{ fontWeight: 400 }}>
                {salaryInfo?.salaryCalculation?.totalSalary} AZN
              </Text>
            </Title>
          </Flex>
        </Loading>
      </div>
    </MainLayout>
  );
};

export default withAuth(CalculationEditProfile);
