import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import {
  ApartmentOutlined,
  DatabaseOutlined,
  HomeOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { CreateModal } from '../main/CreateModal';
import { useQuery } from 'react-query';
import { getCorps } from '../../api/services';
import { useAuth } from '../../hooks/useAuth';
import { Roles } from '../../constants/roles';
import { CORPS } from '../../constants/corps';

const { SubMenu } = Menu;

const WizardMenu: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const location = useLocation();
  const { userInfo } = useAuth();

  const showModal = () => setOpen(true);
  const handleCancel = () => setOpen(false);

  const { data: queries = CORPS } = useQuery('corps', getCorps);

  useEffect(() => {
    if (collapsed) {
      setOpenKeys([]);
    }
  }, [collapsed]);

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  return (
    <>
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        defaultSelectedKeys={['/']}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        style={{ height: '100%', borderRight: 0, paddingTop: '16px' }}
      >
        <Menu.Item
          key="/"
          icon={<HomeOutlined style={{ marginRight: '8px' }} />}
        >
          <Link to={'/'}>Ana Səhifə</Link>
        </Menu.Item>
        {queries?.map((item: any) =>
          item.militarySpaces.length === 0 ? (
            <Menu.Item
              key={item.id}
              icon={<HomeOutlined style={{ marginRight: '8px' }} />}
            >
              <Link to={item.link}>{item.name}</Link>
            </Menu.Item>
          ) : (
            <SubMenu
              key={item.id}
              icon={<ApartmentOutlined />}
              title={item.name}
            >
              {item.militarySpaces.map((section: any) => (
                <Menu.Item key={`section-${section.id}`}>
                  <Link to={`/sections/${section.id}`}>{section.name}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ),
        )}
        <Menu.Item
          key="/stats"
          icon={<PieChartOutlined style={{ marginRight: '8px' }} />}
        >
          <Link to={'/stats'}>Statistika</Link>
        </Menu.Item>
        <Menu.Item
          key="/logs"
          icon={<DatabaseOutlined style={{ marginRight: '8px' }} />}
        >
          <Link to={'/logs'}>Hərəkətlər</Link>
        </Menu.Item>
      </Menu>
      <CreateModal
        open={open}
        handleCancel={handleCancel}
        showModal={showModal}
      />
    </>
  );
};

export default WizardMenu;
